* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dictionary-container {
  height: auto;
  color: #3f3d3d;
  transition: all 0.2s linear;
  overflow: hidden;
  padding-bottom: 1rem;
  display: inline-block;
  width: 60%;
}

.dictionary-container h1 {
  font-size: 1rem;
  font-weight: normal;
}

.dictionary-row-one {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.dictionary-row-one h2 {
  font-size: 1.7rem;
  margin-left: 1.5rem;
}

.voice-button {
  height: 1.5rem;
  width: 1.5rem;
  background-color: #e08f8e;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  cursor: pointer;
  transition: all 0.2s linear;
}

.voice-button:hover {
  background-color: #e2a7a6;
}

#pronounciation {
  margin-left: 3rem;
  color: #656161;
  font-weight: 300;
  font-size: 0.9rem;
}

.dictionary-header {
  color: #656161;
  font-weight: 300px;
  margin-top: 1.5rem;
}

.dictionary-ol {
  font-size: 0.8rem;
  font-weight: normal;
  margin-left: 2rem;
  margin-top: 1rem;
  width: 80%;
}

.dictionary-ol li {
  padding-left: 1rem;
  margin-bottom: 1.3rem;
}

.dictionary-row-two {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.dictionary-row-two p {
  font-size: 0.8rem;
  color: #858585;
  margin: 0 1.2rem;
}

.synonyms-container {
  display: flex;
  color: #3f3d3d;
  font-size: 0.6rem;
  flex-wrap: wrap;
}

.synonym {
  outline: 1px solid #e89897;
  padding: 0.2rem 0.6rem;
  border-radius: 15px;
  margin: 0.2rem 0.5rem;
  transition: all 0.2s linear;
}

.dictionary-barrier {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.show-btn {
  outline: 0.2px solid #858585;
  padding: 0.5rem 1.5rem;
  font-size: 0.8rem;
  border-radius: 20px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show-btn i {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.show-btn:hover {
  background: #f3f3f3;
}

.dictionary-barrier hr {
  width: 100%;
  height: 0.2px;
  outline: none;
  border: none;
  background: #858585;
}

.skills-container {
  margin-top: 2rem;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s linear, opacity 0.2s linear;
}

.skills-icons-container {
  display: flex;
  flex-wrap: wrap;
}
/* .skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: 0.02px solid #a2a2a2;
  height: 6vw;
  width: 6.5vw;
  margin: 1rem;
  border-radius: 0.42vw;
  cursor: pointer;
  transition: all 0.2s linear;
}

.skill p {
  display: none;
  font-size: 0.6rem;
  margin-top: 0.5rem;
}

.skill img {
  width: 2.5vw;
  height: auto;
}

#figma {
  width: 2vw;
}

#java {
  margin-bottom: 0.3rem;
} */

@media screen and (max-width: 870px) {
  .dictionary-container {
    width: 80%;
  }

  .skills-container {
    margin-top: 1rem;
  }
  .skill {
    height: 8vw;
    width: 8.5vw;
  }
  .skill img {
    width: 4vw;
  }
}

@media screen and (max-width: 770px) {
  .dictionary-container {
    width: 100%;
  }
  .skills-container {
    margin-top: 0.5rem;
  }
  .skill {
    height: 9vw;
    width: 9.5vw;
  }
  .skill img {
    width: 5vw;
  }

  #figma {
    width: 3vw;
  }
}

@media screen and (max-width: 425px) {
  .dictionary-container h1 {
    font-size: 0.8rem;
  }

  .dictionary-row-one h2 {
    font-size: 1.5rem;
  }

  #pronounciation {
    font-size: 0.7rem;
  }

  .dictionary-header {
    font-size: 0.8rem;
  }

  .dictionary-ol li {
    font-size: 0.6rem;
  }

  .dictionary-row-two p {
    font-size: 0.6rem;
  }

  .synonym {
    font-size: 0.4rem;
  }

  .skill {
    height: 12vw;
    width: 12.5vw;
  }

  .skill img {
    width: 6vw;
  }

  #figma {
    width: 4vw;
  }

  .show-btn {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 320px) {
  .skill {
    height: 16vw;
    width: 16.5vw;
  }

  .skill img {
    width: 8vw;
  }

  #figma {
    width: 6vw;
  }
}
