* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.accordion-container {
  color: #949191;
  width: 60%;
  margin-top: 3rem;
}
.accordion-container h1 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.question {
  font-size: 0.9rem;
  font-weight: 600;
}

.question-container {
  padding: 1rem 0.25rem 0.5rem 0.25rem;
  border-bottom: 1px solid #949191;
  cursor: pointer;
}

.question-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#question-container-1 {
  border-top: 1px solid #949191;
}

.answer-container {
  padding-top: 0.5rem;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s linear, opacity 0.3s linear;
  padding-bottom: 0.5rem;
}

.answer {
  font-weight: 300;
  font-size: 0.87rem;
}

@media screen and (max-width: 870px) {
  .accordion-container {
    width: 80%;
  }
}

@media screen and (max-width: 770px) {
  .accordion-container {
    width: 100%;
    margin-top: 2rem;
  }

  .accordion-container h1 {
    font-size: 1rem;
  }

  .question,
  .answer {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 500px) {
  .accordion-container {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 425px) {
  .accordion-container h1 {
    font-size: 0.8rem;
  }

  .question,
  .answer {
    font-size: 0.6rem;
  }
}
