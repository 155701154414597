* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.projects-page-container {
  display: flex;
  height: 80vh;
  overflow-y: scroll;
}

.projects-container {
  padding: 3rem 1rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  flex-shrink: 30;
}
