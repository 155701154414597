* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.project-container {
  display: table;
  margin-bottom: 3rem;
  margin-right: 3rem;
  /* flex-shrink: 1;
  flex-grow: 1; */
  transition: all 0.3s linear;
}

.project-img {
  height: 39vh;
  cursor: pointer;
  transition: all 0.1s linear;
}

.project-img:hover {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.project-caption-container {
  display: table-caption;
  caption-side: bottom;
  margin: 0;
  cursor: pointer;
}

.project-caption-container:hover {
  text-decoration: underline;
}

.project-url {
  display: block;
  text-decoration: none;
}

.project-tag,
.project-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-decoration: none;
}

#small {
  width: 35vh;
}
.project-caption-container a {
  font-size: 0.7rem;
  font-weight: lighter;
  color: #6c6a6a;
}

.project-tag {
  font-size: 0.8rem;
  color: #3f3d3d;
}

@media screen and (max-width: 500px) {
  .project-img {
    width: 90vw;
    height: auto;
  }
}
