* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.home-container {
  width: 100vw;
  /* height: 200vh; */
  padding: 4rem 5rem;
}

@media screen and (max-width: 500px) {
  .home-container {
    padding: 3rem 2rem;
  }
}
