* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.sidebar-container {
  height: 80vh;
  width: 40vw;
  background: #282828;
  position: sticky;
  padding: 1rem;
  padding-bottom: 1.5rem;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 2;
}

.sidebar-img {
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
}

.sidebar-link {
  color: #b1b1b1;
  font-size: 0.8rem;
}

.sidebar-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.sidebar-top-row h1 {
  color: #f9f9f9;
  font-size: 1.3rem;
}

.sidebar-btn {
  display: flex;
  align-items: center;
  background-color: #3e3e3e;
  font-size: 0.8rem;
  padding: 0.5rem 0.8rem;
  color: #878787;
}
.sidebar-btn-link {
  text-decoration: none;
  cursor: pointer;
}
.sidebar-btn p {
  margin-left: 0.5rem;
}

.description {
  font-size: 0.9rem;
  margin-top: 1.5rem;
  color: #f9f9f9;
  font-weight: lighter;
}

.sidebar-stack-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.stack-img {
  height: 1rem;
  margin-right: 0.7rem;
}

.sidebar-divider {
  height: 1px;
  width: 99%;
  border: none;
  background: #404040;
  margin: auto;
  margin-top: 2rem;
}

.sidebar-divider-header {
  color: #f9f9f9;
  margin-top: 1rem;
}

.sidebar-close {
  position: absolute;
  top: 4%;
  left: 4%;
  cursor: pointer;
}

.sidebar-close img {
  height: 2vh;
}

.sidebar-close:hover {
  color: rgba(0, 0, 0, 0.8);
}
/* .related-img-container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 1rem;
}
.related-img {
  height: 11.5rem;
  cursor: pointer;
  margin-right: 1rem;
} */

@media screen and (max-width: 500px) {
  .sidebar-container {
    width: 100vw;
    position: absolute;
  }

  .sidebar-link {
    font-size: 0.6rem;
  }

  .sidebar-top-row h1 {
    font-size: 1rem;
  }

  .sidebar-btn {
    font-size: 0.6rem;
  }

  .sidebar-top-row {
    margin-top: 0.5rem;
  }

  .sidebar-close {
    top: 5%;
    left: 7%;
  }
}
