* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.animation-container,
.search-container,
.loader-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #e89897;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.animation-logo {
  width: 13%;
  height: auto;
  margin-bottom: 3%;
}

.animation-searchbar {
  width: 50%;
  outline: 1px solid #b4b4b4;
  padding: 1em 1.5em;
  border-radius: 5vw;
  display: flex;
  align-items: center;
  color: #b4b4b4;
}

#hide {
  visibility: hidden;
}

/* TYPING ANIMATION */
.animation-typing {
  font-weight: normal;
  overflow: hidden;
  border-right: 0.15em solid #e89897;
  font-size: 18px;
  white-space: nowrap; /*ensures text doesn't wrap to the next line*/
  width: 0;
  animation: typing 1s steps(10, end) forwards, blink 0.8s infinite;
}

.animation-typing-container {
  display: inline-block;
  margin-left: 1rem;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: #e89897;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
